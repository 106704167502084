<template>
  <div>
    <div class="course_detail_list_page">
      <div class="table_top">
        <div class="title">课程详情</div>
      </div>
      <el-table header-align="center" :show-overflow-tooltip="true" stripe header-cell-class-name="header_row_style" :data="list" style="width: 100%">
        <el-table-column align="center" prop="id" label="序号"></el-table-column>
        <el-table-column align="center" prop="semester" label="购买学期">
            <template slot-scope="scope">
                <div v-html="scope.row.semester"></div>
            </template>
        </el-table-column>
        <el-table-column align="center" prop="number" label="购买人数"></el-table-column>
        <el-table-column align="center" prop="validity" label="有效期"></el-table-column>
        <el-table-column  align="center" label="操作">
          <template slot-scope="scope">
            <div class="table_btns">
              <div class="table_btn" @click="add(scope.row)">增加学员</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
  //   import myTable from '@/components/admin/table.vue'
  import comPagination from '@/components/admin/pagination.vue'
  export default {
    name: 'graduationStudentList',
    components: {
      comPagination,
      //   myTable
    },
    data() {
      return {
        columns: [
        {
          prop: 'id',
          label: '序号'
        },
        {
          prop: 'semester',
          label: '购买学期',
        },
        {
          prop: 'number',
          label: '购买人数',
        },
        {
          prop: 'validity',
          label: '有效期',
        }],
        list: [],
        //分页
        params: {
          page: 0,
          size: 10,
          total: 0
        },
      }
    },
    created() {},
    mounted() {
      if (this.$route.query.type === 'jump') {
        this.$nextTick(()=>{
          window.scrollTo(0,500);
        })
      }
      this.init()
    },
    methods: {
      //初始化
      init() {
        this.getInfo()
      },
      search() {
        this.params.page = 1
        this.params.size = 10
        this.getInfo()
      },
      getInfo() {
        let data = {
          schoolId: this.$store.state.userInfo.schoolId
        }
        this.list = []
        this.api.admin.payCourseList(data).then((res) => {
          this.list = res.data
          this.list.forEach(item => {
              let str = ''
               item.semesterList.map(val => {
                str = str + val.name + `<br/>`
              })
              item.semester = str
            item.validity = this.common.dateFormat(item.beginDate, 'yyyy-MM-dd') + ' - ' + this.common.dateFormat(item.endDate, 'yyyy-MM-dd')
          })
        })
      },
      add(val) {
          sessionStorage.setItem('buySemesterIds',val.semesterIds)
          this.$store.commit('setOpenBuy',true);
          this.$store.commit('setBuyStep',1);
      },
      //分页 todo 待测试
      changePage(val) {
        this.params.page = val
        this.getInfo()
      }
    },
  }
</script>

<style lang="scss">
  .course_detail_list_page {
    .table_top {
      @include flex(row, space-between, center);
      margin-bottom: 30px;

      .title {
        font-size: 34px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #cc1a30;
        // margin-bottom: 30px;
      }
    }

    .code_block {
      @include flex(row, flex-end, center);

      .el-input__inner {
        height: 60px;
        width: 250px;
        font-size: 20px;
      }
    }

    .el-table .cell {
      white-space: pre-line;
    }
  }
</style>
<style lang="scss">
  // .header_row_style {
  //   color: #fff;
  //   background: #cc1a30 !important;
  //   font-size: 24px;
  //   font-family: Alibaba PuHuiTi;
  //   font-weight: bold;
  //   color: #ffffff;
  // }

  .el-table .cell {
    line-height: normal !important;
  }

  .table_flex {
    position: relative;
    @include flex();
  }

  .el-table td {
    border-bottom: 1px solid #cc1a30;
  }

  .el-table tr:last-child td {
    border-bottom: solid 4px #cb1b31 !important;
  }

  .table_btns {
    @include flex();
  }
</style>